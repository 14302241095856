import axios from "axios";
import { dynamicRequest } from "../../../../../general/app/pages/DynamicFrontend/DynamicSectionsCrud";

export const API_URL_CASE = `${process.env.REACT_APP_API_URL}/kamervragen_case_identifier/`;

export function addCase(searchId) {
  return axios.post(API_URL_CASE + searchId, {});
}

export function getDocumentListParams(queryParams = {}) {
  const { pageNumber, pageSize } = queryParams;
  const data = {
    case_identifier: queryParams.case_identifier,
    question_identifier: queryParams.question_identifier
  };

  return dynamicRequest(
    "post",
    `/kamervragen_question_analysis_similar_documents?from=${pageNumber *
      pageSize -
      pageSize}&size=${pageSize}`,
    data
  );
} 
