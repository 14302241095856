import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxBatch } from "@manaflair/redux-batch";
import { persistStore } from "redux-persist";
import logger from "redux-logger";
import {
  createStateSyncMiddleware,
  initMessageListener
} from "redux-state-sync";

const { rootReducer, rootSaga} = require(`../../project-${process.env.REACT_APP_PROJECT}/redux/rootReducer`);

const config = {
  blacklist: ["persist/PERSIST", "persist/REHYDRATE"]
};
const syncMiddlewares = [createStateSyncMiddleware(config)];

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  sagaMiddleware,
  ...syncMiddlewares
];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
  enhancers: [reduxBatch]
});

export const persistor = persistStore(store);
initMessageListener(store);

sagaMiddleware.run(rootSaga);

export default store;
