import React from "react";

export default function DynamicComponent(componentImport) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = { Component: null };
    }

    componentWillUnmount() {
      this.setState({ Component: null });
    }

    componentDidMount() {
      componentImport().then(loaded =>
        this.setState({ Component: loaded.default })
      );
    }

    render() {
      if (this.state.Component === null) {
        return <div>Loading component, please wait</div>;
      }

      return <this.state.Component {...this.props} />;
    }
  };
}