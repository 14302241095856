import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/favorites`;

export function getFavoriteList(
  queryParams = {},
  search_keyword = "",
  favorite_type = "default"
) {
  const {
    pageNumber = 1,
    pageSize = 5,
    sortField = "updated_at",
    sortOrder = "desc"
  } = queryParams;
  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      search_keyword !== "" ? `&filter[favorite_name]=${search_keyword}` : ""
    }${
      favorite_type === "team_overview" ? `&favorite_type=${favorite_type}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function getFavoriteListId(id, type) {
  let config = {
    method: "get",
    url: `${API_URL}?filter[id]=${id}${
      type !== undefined ? `&favorite_type=${type}` : ""
    }`,
    data: {}
  };
  return axios(config);
}

export function getFavOrder(
  queryParams = {},
  search_keyword = "",
  favorite_type = "default"
) {
  return axios.all([
    getFavoriteList(
      {
        ...queryParams,
        sortField: "updated_at",
        sortOrder: "desc",
        pageSize: 3
      },
      search_keyword,
      favorite_type
    ),
    getFavoriteList(
      {
        ...queryParams,
        sortField: "favorite_name",
        sortOrder: "asc",
        pageSize: 5
      },
      search_keyword,
      favorite_type
    )
  ]);
}

export function addFavoriteList(favData) {
  return axios.post(API_URL, favData);
}

export function removeFavoriteList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function updateFavoriteList(id, updateData = {}) {
  return axios.put(`${API_URL}/${id}`, updateData);
}
