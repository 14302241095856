/**
 * Map type value to filter checkboxes value
 */
export const typeMapping = {
  Agenda: "Agenda",
  Handelingen: "Handelingen",
  Kamerstukken: "Kamerstuk",
  Kamervragen: "Kamervragen (Aanhangsel), Kamervragen zonder antwoord",
  Moties: "Moties",
  "Niet-dossierstukken": "Niet-dossierstuk",
  Tractatenblad: "Tractatenblad",
  Staatscourant: "Staatscourant, Staatsblad",
  Gemeenteblad: "Gemeenteblad",
  "Provinciaal blad": "Provinciaal blad",
  Waterschapsblad: "Waterschapsblad",
  "Blad Gemeenschappelijke regeling": "Blad gemeenschappelijke regeling",
  Beleidsnota: "Beleidsnota",
  Besluit: "Besluit",
  Brief: "Brief",
  Convenant: "Convenant",
  Jaarplan: "Jaarplan",
  Jaarverslag: "Jaarverslag",
  Publicatie: "Publicatie",
  Rapport: "Rapport",
  Regeling: "Regeling",
  Richtlijn: "Richtlijn",
  Vergaderstuk: "Vergaderstuk",
  "Wob-verzoek": "Wob-verzoek",
  Overig:
    "Ambtsbericht, Begroting, Brochure, Circulaire, Diplomatieke verklaring, Formulier, Geluidsfragment, Mediatekst, Kaart, Toespraak, Vergunning, Video, Vraag en antwoord",
  "IMG": "IMG",
  Gemeentevergadering: "Gemeentevergadering",
  "SODM (bèta)": "SODM-publicatie",
  Nieuws: "Nieuws"
};

export const subTypeDocTypeMapping = {
  Stemmingen: "Handelingen",
  Debat: "Handelingen",
  Anders: "Handelingen",
  Brief: "Kamerstuk",
  Motie: "Kamerstuk",
  Verslag: "Kamerstuk",
  Amendement: "Kamerstuk",
  "Voorstel van wet": "Kamerstuk",
  AndersKamerstukken: "Kamerstuk",
  "Kamervragen zonder antwoord": "Kamervragen zonder antwoord",
  "Kamervragen met antwoord": "Kamervragen (Aanhangsel)",
  "Antwoord (mededeling)": "Kamervragen (Aanhangsel)",
  "Antwoord overig": "Kamervragen (Aanhangsel)"
};

export const subTypeMapping = {
  Stemmingen: "Stemmingen,Stemming",
  Debat: "Plenair debat,Debat",
  Anders: "Plenair debat,Debat,Stemmingen,Stemming",
  Brief: "Brief,Brief regering",
  Motie: "Motie",
  Verslag: "Verslag",
  Amendement: "Amendement",
  "Voorstel van wet": "Voorstel van wet",
  AndersKamerstukken:
    "Brief,Brief regering,Motie,Verslag,Amendement,Voorstel van wet",
  "Kamervragen met antwoord": "Antwoord,Nader antwoord",
  "Antwoord (mededeling)": "Mededeling",
  "Antwoord overig": "Mededeling,Antwoord,Nader antwoord"
};

export const notSubDocumentabel = {
  "Plenair debat,Debat,Stemmingen,Stemming": "Handelingen - Anders",
  "Brief,Brief regering,Motie,Verslag,Amendement,Voorstel van wet":
    "Kamerstukken - Anders",
  "Mededeling,Antwoord,Nader antwoord": "Kamervragen - Antwoord overig",
  not_exist: "Kamervragen zonder antwoord"
};
