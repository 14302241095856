import React, { Component } from "react";
import store from "../../../redux/store";
import SnackbarContext from "../../../template/layout/components/snackbars/SnackbarProvider";
import { FormattedMessage } from "react-intl";

const {
  actions
} = require(`../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

const defaultError = <FormattedMessage id='DEFAULT.ERROR' />;

export class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = SnackbarContext;

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  shouldComponentUpdate() {
    this.setState({ hasError: false });
    return false;
  }

  render() {
    const { hasError } = this.state;
    const snackbarUIProps = this.context;

    if (hasError) {
      store.dispatch(actions.logout());

      snackbarUIProps.setParams((prev) => ({
        ...prev,
        open: true,
        variant: "error",
        message: defaultError,
        vertical: "top",
        horizontal: "right"
      }));
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundry;
