import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../template/layout";

const {actions} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

class Logout extends Component {
  componentDidMount() {
    const { token: authToken } = this.props;
    this.props.logout(authToken);
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to='/auth/login' />
    );
  }
}

export default connect(
  ({ auth }) => ({
    hasAuthToken: Boolean(auth.authToken),
    token: auth.authToken
  }),
  actions
)(Logout);
