import React, { createContext, useState, useContext } from "react";
const listUIContext = createContext();

export function useListUIContext() {
  return useContext(listUIContext);
}

export default function ListContext({ children }) {
  const [fetchActive, setFetchActive] = useState(false);
  const value = {
    fetchActive,
    setFetchActive
  };
  return (
    <listUIContext.Provider value={value}>{children}</listUIContext.Provider>
  );
}
