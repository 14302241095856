import React, { useContext, useMemo } from "react";
import axios from "axios";
import SnackbarContext from "../template/layout/components/snackbars/SnackbarProvider";
import { getConfig } from "../template/i18n";
import store from "./store";

const WithAxios = ({ children, intl }) => {
  const { setParams } = useContext(SnackbarContext);

  useMemo(() => {
    axios.interceptors.request.use(
      config => {
        const {
          auth: { authToken }
        } = store.getState();

        const lang = getConfig().selectedLang;

        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
          config.headers.language = lang;
        }

        return config;
      },
      err => Promise.reject(err)
    );

    axios.interceptors.response.use(
      response => {
        const {
          data: { status, message, message_code, message_level }
        } = response;
        if (!status) {
          setParams(prev => ({
            ...prev,
            open: true,
            variant:
              message_level !== undefined
                ? message_level.toLowerCase()
                : "error",
            message:
              message_code !== undefined ? (
                <div>
                  <p className='mb-1'>{`${intl.formatMessage({
                    id: "CODE"
                  })}: ${message_code}`}</p>
                  <p className='mb-1'>{message}</p>
                </div>
              ) : (
                message
              ),
            vertical: "top",
            horizontal: "right"
          }));
        }
        return response;
      },
      () => {
        setParams(prev => ({
          ...prev,
          open: true,
          variant: "error",
          message: intl.formatMessage({
            id: "DEFAULT.ERROR"
          }),
          vertical: "top",
          horizontal: "right"
        }));
      }
    );
  }, [setParams, intl]);

  return children;
};

export default WithAxios;
