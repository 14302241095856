import React from "react";
import { useIntl } from "react-intl";

const inputLabel = ({ label, touched, error, customFeedbackLabel, intl }) => {
  if (touched && error) {
    return <div className='invalid-feedback'>{error}</div>;
  }

  if (touched && !error && label) {
    return (
      <div className='valid-feedback'>
        {label}{" "}
        {intl.formatMessage({
          id: "ENTERED.CORRECT"
        })}
      </div>
    );
  }

  return (
    <div className='feedback'>
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && (
        <>
          {intl.formatMessage({
            id: "PLEASE.ENTER"
          })}{" "}
          <b>{label}</b>
        </>
      )}
    </div>
  );
};

const selectLabel = ({ label, touched, error, customFeedbackLabel, intl }) => {
  if (touched && error) {
    return <div className='invalid-feedback'>{error}</div>;
  }

  return (
    <div className='feedback'>
      {customFeedbackLabel && <>{customFeedbackLabel}</>}
      {!customFeedbackLabel && label && (
        <>
          {intl.formatMessage({
            id: "PLEASE.SELECT"
          })}{" "}
          <b>{label}</b>
        </>
      )}
    </div>
  );
};

export function FieldFeedbackLabel({
  label,
  touched,
  error,
  type,
  customFeedbackLabel
}) {
  const intl = useIntl();

  switch (type) {
    case "text":
      return inputLabel({ label, touched, error, customFeedbackLabel, intl });
    default:
      return selectLabel({ label, touched, error, customFeedbackLabel, intl });
  }
}
