export const subject_length = 17;
export const parliament_length = 8;
export const official_length = 6;
export const policy_length = 12;
export const other_length = 2;
export const organization_length = 3;

export const filterStatusActive = (param = 0, type = "") => {
  switch (type) {
    case "subject_filter_length":
      return param === subject_length ? true : false;
    case "parliament_filter_length":
      return param === parliament_length ? true : false;
    case "official_filter_length":
      return param === official_length ? true : false;
    case "policy_filter_length":
      return param === policy_length ? true : false;
    case "other_filter_length":
      return param === other_length ? true : false;
    case "oragnization_filter_length":
      return param === organization_length ? true : false;
    default:
      return false;
  }
};

export const checkFilterStatus = (filterState = {}) => {
  let filterStatus = false;
  if (Object.keys(filterState).length > 0) {
    const {
      dateFilter,
      fileNumberFilter,
      subjectFilter,
      organizationFilter,
      indexFilter,
      documentTypeFilter
    } = filterState;

    if (dateFilter.date_from !== "" || dateFilter.date_to !== "") {
      filterStatus = true;
    }
    if (
      fileNumberFilter.file_number !== "" ||
      fileNumberFilter.file_subnumber !== ""
    ) {
      filterStatus = true;
    }
    if (subjectFilter.subjectItems.length > 0) {
      filterStatus = true;
    }
    if (organizationFilter.subjectItems.length > 0) {
      filterStatus = true;
    }
    if (indexFilter.subjectItems.length === 1) {
      filterStatus = true;
    }
    if (
      documentTypeFilter.parliament_docs.subjectItems.length > 0 ||
      documentTypeFilter.parliament_docs.subtypeSubjectItems.length > 0 ||
      documentTypeFilter.parliament_docs.notSubtypeSubjectItems.length > 0 ||
      documentTypeFilter.official_docs.subjectItems.length > 0 ||
      documentTypeFilter.policy_docs.subtypeSubjectItems.length > 0 ||
      documentTypeFilter.other_docs.subjectItems.length > 0
    ) {
      filterStatus = true;
    }
  }
  return filterStatus;
};
