import * as requestFromServer from "./adminCompaniesCrud";
import { callTypesCompanies, adminCompaniesSlice } from "./adminCompaniesSlice";

const { actions: adminCompaniesActions } = adminCompaniesSlice;

export const fetchAllCompanies = () => async () => {
  const queryParams = {
    pageNumber: 0,
    pageSize: 0
  };
  const response = await requestFromServer.getCompanyList(queryParams);
  const {
    data: { companies }
  } = response.data;
  return companies;
};

export const fetchAllRoles = () => async () => {
  const response = await requestFromServer.getRoles();
  const {
    data: { roles }
  } = response.data;
  return roles;
};

export const fetchAllMenuItemGroups = () => async () => {
  const queryParams = {
    pageNumber: 0,
    pageSize: 0
  };
  const response = await requestFromServer.getMenuItemGroups(queryParams);
  const {
    data: { menus }
  } = response.data;
  return menus;
};

export const fetchAdminCompanies = (
  queryParams,
  search_keyword = "",
  search_status = ""
) => async dispatch => {
  dispatch(
    adminCompaniesActions.startCall({ callType: callTypesCompanies.list })
  );
  try {
    const response = await requestFromServer.getCompanyList(
      queryParams,
      search_keyword,
      search_status
    );
    const {
      data: { companies, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        adminCompaniesActions.storeSearch({
          hits_total: total,
          sources: companies
        })
      );
    }, 500);
  } catch (e) {
    dispatch(
      adminCompaniesActions.catchError({ callType: callTypesCompanies.list })
    );
  }
};

export const addAdminCompanies = data => async dispatch => {
  dispatch(
    adminCompaniesActions.startCall({ callType: callTypesCompanies.list })
  );
  try {
    const response = await requestFromServer.addCompanyList(data);
    const { status, message, message_code, message_level } = response.data;
    if (status) {
      const {
        data: { id }
      } = response.data;

      const responseCompany = await requestFromServer.getCompanyList();
      const {
        data: { companies, total }
      } = responseCompany.data;

      dispatch(
        adminCompaniesActions.storeSearch({
          hits_total: total,
          sources: companies
        })
      );
      return {
        id: id,
        status: status,
        msg: message,
        message_code: message_code,
        message_level: message_level
      };
    }
    return {
      status: status,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(
      adminCompaniesActions.catchError({ callType: callTypesCompanies.list })
    );
  }
};

export const updateAdminCompanies = (
  queryParams,
  search_keyword,
  data,
  companyId,
  status,
  saveExitState
) => async dispatch => {
  dispatch(
    adminCompaniesActions.startCall({ callType: callTypesCompanies.list })
  );
  try {
    const response = await requestFromServer.updateCompanyList(data, companyId);
    const {
      status: responseStatus,
      message,
      message_code,
      message_level
    } = response.data;
    if (responseStatus && !saveExitState) {
      const response = await requestFromServer.getCompanyList(
        queryParams,
        search_keyword,
        status
      );
      const {
        data: { companies, total }
      } = response.data;

      dispatch(
        adminCompaniesActions.storeSearch({
          hits_total: total,
          sources: companies
        })
      );
    }
    return {
      status: responseStatus,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(
      adminCompaniesActions.catchError({ callType: callTypesCompanies.list })
    );
  }
};

export const removeAdminCompanies = (
  queryParams,
  search_keyword,
  id,
  include_users,
  searchStatus
) => async dispatch => {
  dispatch(
    adminCompaniesActions.startCall({ callType: callTypesCompanies.list })
  );
  try {
    const response = await requestFromServer.removeCompanyList(
      id,
      include_users
    );
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getCompanyList(queryParams, search_keyword, searchStatus)
          .then(response_1 => {
            const {
              data: { companies, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                adminCompaniesActions.storeSearch({
                  hits_total: total,
                  sources: companies
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              adminCompaniesActions.catchError({
                callType: callTypesCompanies.list
              })
            );
          });
      }, 500);
    } else {
      dispatch(
        adminCompaniesActions.catchError({ callType: callTypesCompanies.list })
      );
    }
  } catch (error_1) {
    dispatch(
      adminCompaniesActions.catchError({ callType: callTypesCompanies.list })
    );
  }
};
