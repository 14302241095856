import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { forgotPassword } from "../_redux/authCrud";
import { Link, useHistory } from "react-router-dom";
import { useIsMounted } from "../../../../template/helpers/useIsMounted";

const {
  actions
} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

const initialValues = {
  email: ""
};

function ForgotPassword(props) {
  const { intl } = props;
  const isMounted = useIsMounted();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        intl.formatMessage({
          id: "AUTH.LOGIN.FORMAT"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        forgotPassword(values.email)
          .then((res) => {
            const { status } = res.data;
            disableLoading();
            if (status) {
              history.push("/auth/success-forgot-password");
            }
          })
          .finally(() => {
            if (isMounted()) {
              disableLoading();
              setSubmitting(false);
            }
          });
      }, 1000);
    }
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.FORGOT.PASS.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.FORGOT.PASS.DESC' />
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        <div className='form-group fv-plugins-icon-container'>
          <label className={"font-weight-bold"}>
            {intl.formatMessage({ id: "PLACEHOLDER.EMAIL" })}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER.EMAIL"
            })}
            type='email'
            autoComplete='off'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name='email'
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div
          className='form-group d-flex flex-wrap justify-content-center align-items-center'
          style={{ gap: 10 }}
        >
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
          >
            <span>
              <FormattedMessage id='ENTER' />
            </span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
          <Link
            className={`btn btn-secondary font-weight-bold px-9 py-3 my-3`}
            to='/auth/login'
          >
            <FormattedMessage id='BACK' />
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, actions)(ForgotPassword));
