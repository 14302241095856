import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/pages`;
export const API_URL_PAGES = `${process.env.REACT_APP_API_URL}/pages/pageType`;
export const API_URL_POWERBI = `${process.env.REACT_APP_API_URL}/azurePowerBi`;
export const API_URL_LOCALE = `${process.env.REACT_APP_API_URL}/pages/updateLocale`;

export function getPagesList(
  queryParams = {},
  search_keyword = {},
  search_status = "",
  search_page_type = ""
) {
  const {
    pageNumber = 1,
    pageSize = 5,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;

  const { name = "", locale = "nl_NL" } = search_keyword;

  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      name !== ""
        ? `&filter[search_page][name]=${name}&filter[search_page][locale]=${locale}`
        : ""
    }${search_status !== "" ? `&filter[page_status]=${search_status}` : ""}${
      search_page_type !== "" ? `&filter[page_type]=${search_page_type}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addPagesList(pageData) {
  return axios.post(API_URL, pageData);
}

export function updatePagesList(id, pageData) {
  return axios.put(`${API_URL}/${id}`, pageData);
}

export function removePagesList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function getTypes() {
  const headers = {
    data: {}
  };
  return axios.get(API_URL_PAGES, headers);
}

export function getPowerBI() {
  const headers = {
    data: {}
  };
  return axios.get(API_URL_POWERBI, headers);
}

export function updatePagesName(pageData) {
  return axios.post(API_URL_LOCALE, pageData);
}
