import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../template/layout";
import { getUserByToken, getProfileMenu } from "./authCrud";
const {actions} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const res = await getUserByToken();
          const {
            data: { user_data }
          } = res.data;

          const profile_res = await getProfileMenu(user_data[0].user_id);
          const {
            data: { profile }
          } = profile_res.data;

          dispatch(props.fulfillUser(user_data[0], profile));
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined, { menu: {}, pages: [] }));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, actions)(AuthInit);
