import * as requestFromServer from "./favoriteListCrud";
import { callTypes, favoriteListSlice } from "./favoriteListSlice";

const { actions: favoriteListActions } = favoriteListSlice;

export const fetchFavoriteList = (queryParams, search_keyword = "") => async (
  dispatch
) => {
  dispatch(favoriteListActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getFavoriteList(
      queryParams,
      search_keyword
    );
    const {
      data: { favorites, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        favoriteListActions.storeSearch({
          hits_total: total,
          sources: favorites
        })
      );
    }, 500);
  } catch (e) {
    dispatch(favoriteListActions.catchError({ callType: callTypes.list }));
  }
};

export const removeFavoriteList = (queryParams, id) => async (dispatch) => {
  dispatch(favoriteListActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.removeFavoriteList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getFavoriteList(queryParams)
          .then((response_1) => {
            const {
              data: { favorites, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                favoriteListActions.storeSearch({
                  hits_total: total,
                  sources: favorites
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              favoriteListActions.catchError({ callType: callTypes.list })
            );
          });
      }, 500);
    } else {
      dispatch(favoriteListActions.catchError({ callType: callTypes.list }));
    }
  } catch (error_1) {
    dispatch(favoriteListActions.catchError({ callType: callTypes.list }));
  }
};
