import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newsList: {},
  newsFilter: {},
  currentActiveItem: "",
  expandMoreSections: [],
  totalNewsFeedCount: 0,
  publishingOrganisationsCount: 0,
  mostActiveFocusArea: "",
  subTopicsList: {}
};

export const newsListSlice = createSlice({
  name: "NewsListSlice",
  initialState: initialState,
  reducers: {
    storeFilter: (state, action) => {
      state.newsFilter = action.payload;
    },
    storeNewsList: (state, action) => {
      state.newsList = action.payload;
    },
    storeExpandMoreSections: (state, action) => {
      state.expandMoreSections = action.payload;
    },
    storeDashboardData: (state, action) => {
      state.totalNewsFeedCount = action.payload.totalNewsFeedCount;
      state.publishingOrganisationsCount =
        action.payload.publishingOrganisationsCount;
      state.mostActiveFocusArea = action.payload.mostActiveFocusArea;
      state.subTopicsList = action.payload.subTopicsList;
    },
    storeCurrentActiveItem: (state, action) => {
      state.currentActiveItem = action.payload;
    },
    logoutUser: () => {
      return initialState;
    }
  }
});
