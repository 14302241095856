import { createSlice } from "@reduxjs/toolkit";

const initialListStateSearch = {
  listLoading: false,
  totalCount: 0,
  entities: []
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const searchHistorySlice = createSlice({
  name: "searchHistoryList",
  initialState: initialListStateSearch,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      }
    },
    storeSearch: (state, action) => {
      const { hits_total, sources } = action.payload;
      state.listLoading = false;
      state.entities = sources;
      state.totalCount = hits_total;
    },
    logoutUser: (state) => {
      return initialListStateSearch;
    }
  }
});
