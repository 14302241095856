import axios from "axios";
export const API_URL = process.env.REACT_APP_API_URL;

export function getCompanyList(
  queryParams = {},
  search_keyword = "",
  search_status = ""
) {
  const {
    pageNumber = 1,
    pageSize = 10,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;
  let config = {
    method: "get",
    url: `${API_URL}/company?from=${pageNumber * pageSize -
      pageSize}&size=${pageSize}${
      search_keyword !== "" ? `&filter[company_name]=${search_keyword}` : ""
    }${
      search_status !== "" ? `&filter[company_status]=${search_status}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addCompanyList(compData) {
  return axios.post(`${API_URL}/company`, compData);
}

export function updateCompanyList(compData, id) {
  return axios.put(`${API_URL}/company/${id}`, compData);
}

export function removeCompanyList(id = 0, includingUsers = false) {
  const headers = {
    "Content-Type": "application/json"
  };

  const dataObj = { including_users: includingUsers ? "1" : "0" };
  return axios.delete(`${API_URL}/company/${id}`, { headers, data: dataObj });
}

export function getRoles() {
  const headers = {
    data: {}
  };
  return axios.get(`${API_URL}/roles`, headers);
}

export function getMenuItemGroups(queryParams = {}) {
  const {
    pageNumber = 1,
    pageSize = 10,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;

  let config = {
    method: "get",
    url: `${API_URL}/menu_item_group?from=${pageNumber * pageSize -
      pageSize}&size=${pageSize}&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}
