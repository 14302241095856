import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/nl";

import enMessages from "./messages/en";
import nlMessages from "./messages/nl";

const allMessages = {
  en: enMessages,
  nl: nlMessages
};

export const allLanguages = {
  nl_NL: "nl",
  en_US: "en"
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
