import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topics: {},
  keywordListValueLoading: [],
  subTopicKeywordTypes: {},
  subTopicKeywordValuesForType: [],
  isError: [],
  isFinalDataLoaded: false
};
export const callTypes = {
  list: "list",
  action: "action"
};
export const topicsSlice = createSlice({
  name: "topicsSlice",
  initialState: initialState,
  reducers: {
    storeTopics: (state, action) => {
      state.topics = action.payload;
    },
    setSubTopicKeywordTypes: (state, action) => {
      state.subTopicKeywordTypes = action.payload.buckets;
    },
    setSubTopicKeywordValuesForType: (state, action) => {
      let arr = state.subTopicKeywordValuesForType;
      let isErrorVal = state.isError;
      arr[action.payload.id] = action.payload.data.buckets;
      isErrorVal[action.payload.id] = action.payload.isError;
      state.subTopicKeywordValuesForType = arr;
      state.isError = isErrorVal;
      state.isFinalDataLoaded = action.payload.isFinalDataLoaded;
    },
    startKeywordValueCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        let arr = state.keywordListValueLoading;
        arr[action.payload.id] = true;
        state.keywordListValueLoading = arr;
      }
    },
    endKeywordValueCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        let arr = state.keywordListValueLoading;
        arr[action.payload.id] = false;
        state.keywordListValueLoading = arr;
      }
    },
    logoutUser: () => {
      return initialState;
    }
  }
});
