import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SnackbarContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { amber } from "@mui/material/colors";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: "#1bc5bd"
  },
  error: {
    backgroundColor: "#bf4040"
  },
  info: {
    backgroundColor: "#0099ff"
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    display: "inline-block"
  },
  iconVariant: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: 8,
    display: "inline-block"
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function SnackbarCustom(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, extraHtml, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
     className={classes[variant]}
      aria-describedby='client-snackbar'
      message={
         <span id='client-snackbar' className={classes.message}>
          <Icon className={classes.iconVariant} />
          {message}
          {extraHtml}
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={onClose}
          >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}
