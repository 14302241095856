import { createSlice } from "@reduxjs/toolkit";

const initialListStateSearch = {
  listLoading: false,
  totalCount: 0,
  entities: [],
  referer_id: 0
};

export const callTypesMenuItems = {
  list: "list",
  action: "action"
};

export const adminMenuItemsSlice = createSlice({
  name: "adminMenuItemsSlice",
  initialState: initialListStateSearch,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypesMenuItems.list) {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypesMenuItems.list) {
        state.listLoading = true;
      }
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypesMenuItems.list) {
        state.listLoading = false;
      }
    },
    storeSearch: (state, action) => {
      const { hits_total, sources } = action.payload;
      state.listLoading = false;
      state.entities = sources;
      state.totalCount = hits_total;
    },
    updateReferer: (state, action) => {
      const { referer_id } = action.payload;
      state.referer_id = referer_id;
    },
    logoutUser: () => {
      return initialListStateSearch;
    }
  }
});
