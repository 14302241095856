/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from "react-router-dom";

export function BreadCrumbs({ items }) {
  const currentUrl = useLocation();
  if (!items || !items.length) {
    return "";
  }

  return (
    <ul className='breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2'>
      <li className='breadcrumb-item'>
        <Link to={{ pathname: "/" }}>
          <i className='flaticon2-shelter text-muted icon-1x' />
        </Link>
      </li>
      {items.map((item, index) => {
        let stateData = "";
        let flag = currentUrl?.pathname?.includes(item?.pathname) ?? false;
        if (flag) stateData = "refresh";
        return (
          <li key={`bc${index}`} className='breadcrumb-item'>
            <Link
              className='text-muted'
              to={{ pathname: item.pathname, state: stateData }}
              onClick={() => {
                if (item?.data !== undefined && !flag) {
                  sessionStorage.setItem(
                    "currentTab",
                    JSON.stringify(item?.data)
                  );
                }
              }}
            >
              {item.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
