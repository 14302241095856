import axios from "axios";

export function dynamicRequest(method, url, data = {}) {
  const config = {
    method: method,
    url: `${process.env.REACT_APP_API_URL}` + url,
    data: data
  };
  return axios(config);
}

export async function getWidgetFilters(filter = '', namespace = '') {
  let config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/get_widget_filter`,
    data: {
      filter: filter,
      namespace: namespace
    },
  };
  let value;
  await axios(config)
    .then(response => {
    if (response.data && response.data['data']) {
      value = response.data['data'];
    }
  })
    .catch(error => {
      console.log(error)
    });
  return value;
}