import {createSlice} from "@reduxjs/toolkit";

const initialDynamicSlice = {
  widgets: []
};

export const dynamicSlice = createSlice({
  name: "dynamicSlice",
  initialState: initialDynamicSlice,
  reducers: {
    storeWidgets: (state, action) => {
      const {widgetId, widgetState} = action.payload;
      state.widgets = {
        ...state.widgets, [widgetId]: widgetState
      };
    },
    logoutUser: () => {
      return initialDynamicSlice;
    }
  }
});
