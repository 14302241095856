import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Snackbar,
} from "@mui/material";
import SnackbarCustom from "../../../partials/widgets/Snackbar/SnackbarCustom";
import WithAxios from "../../../../redux/withAxios";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

const initialData = {
  open: false,
  variant: "success",
  message: "This is a success message!",
  vertical: "bottom",
  horizontal: "right",
  hideDuration: 6000,
  snackbarStyle: {}
};

const SnackbarContext = createContext();

export function useSnackbarContext() {
  return useContext(SnackbarContext);
}

export default SnackbarContext;

export function SnackbarProvider({ children }) {
  const [params, setParamsBase] = useState(initialData);
  const history = useHistory();
  const theme = createTheme({
    overrides: {
      MuiSnackbarContent: {
        message: params.snackbarStyle
      }
    }
  });
  const setParams = useCallback(nextParams => {
    setParamsBase(prevParams => {
      if (isFunction(nextParams)) {
        nextParams = nextParams(prevParams);
      }

      if (isEqual(prevParams, nextParams)) {
        return prevParams;
      }

      return nextParams;
    });
  }, []);
  const intl = useIntl();

  const value = {
    params,
    setParamsBase,
    setParams
  };

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setParams({ ...params, open: false });
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Snackbar
          anchorOrigin={{
            vertical: params.vertical,
            horizontal: params.horizontal
          }}
          open={params.open}
          autoHideDuration={params.hideDuration}
          onClose={handleClose}
        >
          <div>
          <SnackbarCustom
            onClick={() => (params?.link ? history.push(params?.link) : "")}
            onClose={handleClose}
            variant={params.variant}
            message={params.message}
            extraHtml={params?.extraHtml ?? ""}
          />
          </div>
        </Snackbar>
        <SnackbarContext.Provider value={value}>
          <WithAxios intl={intl}>{children}</WithAxios>
        </SnackbarContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
