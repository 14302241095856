import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/menus`;
export const API_URL_LOCALE = `${process.env.REACT_APP_API_URL}/menu/updateLocale`;

export function getMenuList(
  queryParams = {},
  search_keyword = {},
  search_status = ""
) {
  const {
    pageNumber = 1,
    pageSize = 10,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;

  const { name = "", locale = "nl_NL" } = search_keyword;

  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      name !== ""
        ? `&filter[search_menu][name]=${name}&filter[search_menu][locale]=${locale}`
        : ""
    }${
      search_status !== "" ? `&filter[menu_status]=${search_status}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addMenuList(menuData) {
  return axios.post(API_URL, menuData);
}

export function updateMenuList(menuData, id) {
  return axios.put(`${API_URL}/${id}`, menuData);
}

export function removeMenuList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function updateMenusName(menuData) {
  return axios.post(API_URL_LOCALE, menuData);
}
