import * as requestFromServer from "./searchHistoryCrud";
import { callTypes, searchHistorySlice } from "./searchHistorySlice";

const { actions: searchHistoryActions } = searchHistorySlice;

export const fetchSearchHistory = (queryParams) => async (dispatch) => {
  dispatch(searchHistoryActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getSearchHistory(queryParams);
    const {
      data: { results, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        searchHistoryActions.storeSearch({
          hits_total: total,
          sources: results
        })
      );
    }, 500);
  } catch (error) {
    dispatch(searchHistoryActions.catchError({ callType: callTypes.list }));
  }
};

export const removeSearchHistory = (queryParams, id) => async (dispatch) => {
  dispatch(searchHistoryActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.removeSearchHistory(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getSearchHistory(queryParams)
          .then((response_1) => {
            const {
              data: { results, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                searchHistoryActions.storeSearch({
                  hits_total: total,
                  sources: results
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              searchHistoryActions.catchError({ callType: callTypes.list })
            );
          });
      }, 500);
    } else {
      dispatch(searchHistoryActions.catchError({ callType: callTypes.list }));
    }
  } catch (error) {
    dispatch(searchHistoryActions.catchError({ callType: callTypes.list }));
  }
};
