import { createSlice } from "@reduxjs/toolkit";

const initialListStateSearch = {
  listLoading: false,
  totalCount: 0,
  entities: [],
  search_keyword: "",
  status: ""
};

export const callTypesCompanies = {
  list: "list",
  action: "action"
};

export const adminCompaniesSlice = createSlice({
  name: "adminCompaniesSlice",
  initialState: initialListStateSearch,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypesCompanies.list) {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypesCompanies.list) {
        state.listLoading = true;
      }
    },
    stopCall: (state, action) => {
      if (action.payload.callType === callTypesCompanies.list) {
        state.listLoading = false;
      }
    },
    storeSearch: (state, action) => {
      const { hits_total, sources } = action.payload;
      state.listLoading = false;
      state.entities = sources;
      state.totalCount = hits_total;
    },
    updateKeyword: (state, action) => {
      const { search_keyword } = action.payload;
      state.search_keyword = search_keyword;
    },
    updateStatus: (state, action) => {
      const { status } = action.payload;
      state.status = status;
    },
    logoutUser: () => {
      return initialListStateSearch;
    }
  }
});
