import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/topics`;
export const API_URL_SUB_TOPIC_KEYWORD_TYPES = `${process.env.REACT_APP_API_URL}/search`;

export function getTopic() {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.get(API_URL, { headers, data: {} })
}

export function addTopic(data) {
  return axios.post(API_URL, data);
}

export function deleteTopic(id) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function updateTopic(id, subject) {
  return axios.put(`${API_URL}/${id}`, {
    topic_subject: subject
  });
}

export function getSubTopicKeywordTypes(config) {
  return axios.post(
    `${API_URL_SUB_TOPIC_KEYWORD_TYPES}?size=20&from=0`,
    config
  );
}