import * as requestFromServer from "./adminMenusCrud";
import { callTypesMenus, adminMenusSlice } from "./adminMenusSlice";

const { actions: adminMenusActions } = adminMenusSlice;

export const fetchAllMenus = () => async () => {
  const queryParams = {
    pageNumber: 0,
    pageSize: 0
  };
  const response = await requestFromServer.getMenuList(queryParams, {}, "1");
  const {
    data: { menus }
  } = response.data;
  return menus;
};

export const fetchAdminMenus = (
  queryParams,
  search_keyword = {},
  search_status = ""
) => async dispatch => {
  dispatch(adminMenusActions.startCall({ callType: callTypesMenus.list }));
  try {
    const response = await requestFromServer.getMenuList(
      queryParams,
      search_keyword,
      search_status
    );
    const {
      data: { menus, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        adminMenusActions.storeSearch({
          hits_total: total,
          sources: menus
        })
      );
    }, 500);
  } catch (e) {
    dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
  }
};

export const addAdminMenus = data => async dispatch => {
  dispatch(adminMenusActions.startCall({ callType: callTypesMenus.list }));
  try {
    const response = await requestFromServer.addMenuList(data);
    const { status, message, message_code, message_level } = response.data;

    if (status) {
      const {
        data: { id }
      } = response.data;

      const responseMenu = await requestFromServer.getMenuList();
      const {
        data: { menus, total }
      } = responseMenu.data;

      dispatch(
        adminMenusActions.storeSearch({
          hits_total: total,
          sources: menus
        })
      );
      return {
        id: id,
        status: status,
        msg: message,
        message_code: message_code,
        message_level: message_level
      };
    }
    return {
      status: status,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
  }
};

export const updateAdminMenus = (
  queryParams,
  search_keyword = {},
  data,
  menuId,
  status,
  saveExitState
) => async dispatch => {
  dispatch(adminMenusActions.startCall({ callType: callTypesMenus.list }));
  try {
    const response = await requestFromServer.updateMenuList(data, menuId);
    const {
      status: resStatus,
      message,
      message_code,
      message_level
    } = response.data;

    if (resStatus && !saveExitState) {
      const response = await requestFromServer.getMenuList(
        queryParams,
        search_keyword,
        status
      );
      const {
        data: { menus, total }
      } = response.data;

      dispatch(
        adminMenusActions.storeSearch({
          hits_total: total,
          sources: menus
        })
      );
    }
    return {
      status: resStatus,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
  }
};

export const removeAdminMenus = (
  queryParams,
  search_keyword = {},
  id,
  searchStatus
) => async dispatch => {
  dispatch(adminMenusActions.startCall({ callType: callTypesMenus.list }));
  try {
    const response = await requestFromServer.removeMenuList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getMenuList(queryParams, search_keyword, searchStatus)
          .then(response_1 => {
            const {
              data: { menus, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                adminMenusActions.storeSearch({
                  hits_total: total,
                  sources: menus
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              adminMenusActions.catchError({
                callType: callTypesMenus.list
              })
            );
          });
      }, 500);
    } else {
      dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
    }
  } catch (error_1) {
    dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
  }
};

export const updateMenusName = (
  details,
  queryParams,
  search_keyword = {},
  search_status = ""
) => async dispatch => {
  dispatch(adminMenusActions.startCall({ callType: callTypesMenus.list }));
  try {
    const response = await requestFromServer.updateMenusName(details);
    const { message, status, message_code, message_level } = response.data;
    if (status) {
      const response = await requestFromServer.getMenuList(
        queryParams,
        search_keyword,
        search_status
      );
      const {
        data: { menus, total }
      } = response.data;

      dispatch(
        adminMenusActions.storeSearch({
          hits_total: total,
          sources: menus
        })
      );
    }
    return {
      status: status,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminMenusActions.catchError({ callType: callTypesMenus.list }));
  }
};
