import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/users`;
export const API_URL_REMOVE = `${process.env.REACT_APP_API_URL}/user`;
export const API_URL_ASSIGN = `${process.env.REACT_APP_API_URL}/user_company`;

export function getUsersList(
  queryParams = {},
  search_keyword = "",
  search_status = "",
  search_role = "",
  referer_id = 0
) {
  const {
    pageNumber = 1,
    pageSize = 5,
    sortField = "created_at",
    sortOrder = "desc"
  } = queryParams;

  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      search_keyword !== "" ? `&filter[search_user]=${search_keyword}` : ""
    }${search_status !== "" ? `&filter[status]=${search_status}` : ""}${
      search_role !== "" ? `&filter[role_id]=${search_role}` : ""
    }${
      referer_id ? `&filter[company_id]=${referer_id}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addUserList(userData) {
  return axios.post(API_URL, userData);
}

export function updateUserList(id, userData) {
  return axios.put(`${API_URL_REMOVE}/${id}`, userData);
}

export function removeUsersList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL_REMOVE}/${id}`, { headers, data: {} });
}

export function addUsersCompanyList(userData) {
  return axios.post(API_URL_ASSIGN, userData);
}

export function updateUsersCompanyList(id, userData) {
  return axios.put(`${API_URL_ASSIGN}/${id}`, userData);
}

export function removeUsersCompanyList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL_ASSIGN}/${id}`, { headers, data: {} });
}
