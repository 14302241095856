import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "../template/layout";
import { AuthPage } from "./modules/Auth";
import { ErrorPage } from "./pages/Error/ErrorPage";
import ListContext from "../../general/template/partials/SearchBox/ListContext";
import DynamicComponent from "../DynamicComponent";
import { searchListSlice } from "../../project-heptagon/app/modules/SearchList";
import { ProductFruits } from "react-product-fruits";
import { getConfig } from "../template/i18n";

export function Routes() {
  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      user: auth.user
    }),
    shallowEqual
  );

  const BasePage = DynamicComponent(() =>
    import(`./../../project-${process.env.REACT_APP_PROJECT}/app/BasePage`)
  );

  // Reset filters
  const { actions: searchActions } = searchListSlice;
  const dispatch = useDispatch();
  dispatch(searchActions.updateKeyword({ search_keyword: "" }));
  dispatch(searchActions.resetFilter({ type: "filter" }));
  dispatch(searchActions.updateIsTopicSubSession({ topic_sub_is_session: 0 }));

  const lang = getConfig().selectedLang;

  if (user) {
    user.username = user.user_name;
    user.firstname = user.user_name;
    user.lastname = user.surname;
  }

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <Layout>
          <ProductFruits
            workspaceCode='rjRyhkifd26jHnjA'
            language={lang}
            user={user}
          />
          <ListContext>
            <BasePage />
          </ListContext>
        </Layout>
      )}
    </Switch>
  );
}
