import { createSlice } from "@reduxjs/toolkit";
import { callTypes } from "../../SearchList";

const initialState = {
  listLoading: false,
  totalCount: 0,
  took: 0,
  entities: [],
  sub_topic_analyse: []
};

export const analyseListSlice = createSlice({
  name: "AnalyseListSlice",
  initialState: initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      }
    },
    listFetched: (state, action) => {
      const { value, hits, took } = action.payload;
      state.listLoading = false;
      state.entities = hits;
      state.totalCount = value;
      state.took = took;
    },
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
    },
    updateSubTopicResults: (state, action) => {
      const { topic_subs } = action.payload.sub_topic;
      state.sub_topic_analyse = topic_subs ?? [];
    },
    logoutUser: () => {
      return initialState;
    }
  }
});
