import * as requestFromServer from "./parliamentListCrud";
import { callTypes, parliamentListSlice } from "./parliamentListSlice";

const { actions: parliamentListActions } = parliamentListSlice;

export const fetchParliamentList = (
  queryParams,
  search_keyword = "",
  filter = {}
) => async dispatch => {
  dispatch(parliamentListActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getParliamentList(
      queryParams,
      search_keyword,
      filter
    );
    const {
      data: { favorites, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        parliamentListActions.storeSearch({
          hits_total: total,
          sources: favorites
        })
      );
    }, 500);
  } catch (e) {
    dispatch(parliamentListActions.catchError({ callType: callTypes.list }));
  }
};

export const removeParliamentList = (queryParams, id) => async dispatch => {
  dispatch(parliamentListActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.removeParliamentList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getParliamentList(queryParams)
          .then(response_1 => {
            const {
              data: { favorites, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                parliamentListActions.storeSearch({
                  hits_total: total,
                  sources: favorites
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              parliamentListActions.catchError({ callType: callTypes.list })
            );
          });
      }, 500);
    } else {
      dispatch(parliamentListActions.catchError({ callType: callTypes.list }));
    }
  } catch (error_1) {
    dispatch(parliamentListActions.catchError({ callType: callTypes.list }));
  }
};

export const fetchAllStatus = () => async () => {
  const response = await requestFromServer.getStatus();
  const { data } = response.data;
  return data;
};

export const fetchAllTypes = () => async () => {
  const response = await requestFromServer.getTypes();
  const { data } = response.data;
  return data;
};

export const fetchAllUsers = (filter = "") => async () => {
  const response = await requestFromServer.getUsers(filter);
  const { data } = response.data;
  return data;
};

export const fetchDocumentTypes = () => async () => {
  return await new Promise(resolve => {
    resolve([
      {
        id: "PARLIAMENT",
        label: "Parlementaire documenten",
        sub_items: [
          { id: "PARLIAMENT_1", label: "Agenda", value: "Agenda" },
          {
            id: "PARLIAMENT_2",
            label: "Handelingen",
            value: "Handelingen",
            sub_items: [
              {
                id: "PARLIAMENT_SUBTYPE_1",
                label: "Stemmingen",
                value: "Stemmingen,Stemming"
              },
              {
                id: "PARLIAMENT_SUBTYPE_2",
                label: "Debat",
                value: "Plenair debat,Debat"
              },
              {
                id: "PARLIAMENT_SUBTYPE_3",
                label: "Anders",
                value: "Plenair debat,Debat,Stemmingen,Stemming"
              }
            ]
          },
          {
            id: "PARLIAMENT_3",
            label: "Kamerstukken",
            value: "Kamerstuk",
            sub_items: [
              {
                id: "PARLIAMENT_SUBTYPE_4",
                label: "Brief",
                value: "Brief,Brief regering"
              },
              { id: "PARLIAMENT_SUBTYPE_5", label: "Motie", value: "Motie" },
              {
                id: "PARLIAMENT_SUBTYPE_6",
                label: "Verslag",
                value: "Verslag"
              },
              {
                id: "PARLIAMENT_SUBTYPE_7",
                label: "Amendement",
                value: "Amendement"
              },
              {
                id: "PARLIAMENT_SUBTYPE_8",
                label: "Voorstel van wet",
                value: "Voorstel van wet"
              },
              {
                id: "PARLIAMENT_SUBTYPE_9",
                label: "Anders",
                value:
                  "Brief,Brief regering,Motie,Verslag,Amendement,Voorstel van wet"
              }
            ]
          },
          {
            id: "PARLIAMENT_4",
            label: "Kamervragen",
            value: "Kamervragen (Aanhangsel), Kamervragen zonder antwoord",
            sub_items: [
              {
                id: "PARLIAMENT_SUBTYPE_10",
                label: "Kamervragen zonder antwoord",
                value: ""
              },
              {
                id: "PARLIAMENT_SUBTYPE_11",
                label: "Kamervragen met antwoord",
                value: "Antwoord,Nader antwoord"
              },
              {
                id: "PARLIAMENT_SUBTYPE_12",
                label: "Antwoord (mededeling)",
                value: "Mededeling"
              },
              {
                id: "PARLIAMENT_SUBTYPE_13",
                label: "Antwoord overig",
                value: "Mededeling,Antwoord,Nader antwoord"
              }
            ]
          },
          {
            id: "PARLIAMENT_5",
            label: "Niet-dossierstukken",
            value: "Niet-dossierstuk"
          }
        ]
      },
      {
        id: "OFFICIAL",
        label: "Officiële Bekendmakingen",
        sub_items: [
          {
            id: "OFFICIAL_1",
            label: "Tractatenblad",
            value: "Tractatenblad"
          },
          {
            id: "OFFICIAL_2",
            label: "Staatscourant",
            value: "Staatscourant, Staatsblad"
          },
          { id: "OFFICIAL_3", label: "Gemeenteblad", value: "Gemeenteblad" },
          {
            id: "OFFICIAL_4",
            label: "Provinciaal blad",
            value: "Provinciaal blad"
          },
          {
            id: "OFFICIAL_5",
            label: "Waterschapsblad",
            value: "Waterschapsblad"
          },
          {
            id: "OFFICIAL_6",
            label: "Blad Gemeenschappelijke regeling",
            value: "Blad gemeenschappelijke regeling"
          }
        ]
      },
      {
        id: "OTHER",
        label: "Overige",
        sub_items: [
          { id: "OTHER_1", label: "IMG", value: "IMG" },
          {
            id: "OTHER_2",
            label: "Gemeentevergadering",

            value: "Gemeentevergadering"
          },
          { id: "OTHER_3", label: "SODM (bèta)", value: "SODM-publicatie" },
          { id: "OTHER_4", label: "Nieuws", value: "Nieuws" },
          { id: "OTHER_5", label: "Aanbesteding", value: "Aanbesteding" },
          {
            id: "OTHER_6",
            label: "Rechtspraak",
            value: ["Uitspraak", "Conclusie"]
          }
        ]
      }
    ]);
  });
};
