import { createSlice } from "@reduxjs/toolkit";

const initialListStateSearch = {
  listLoading: false,
  totalCount: 0,
  entities: [],
  search_keyword: "",
  referer_id: 0,
  filter: {},
  addItemRedirect: ""
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const parliamentListSlice = createSlice({
  name: "parliamentListSlice",
  initialState: initialListStateSearch,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      }
    },
    storeSearch: (state, action) => {
      const { hits_total, sources } = action.payload;
      state.listLoading = false;
      state.entities = sources;
      state.totalCount = hits_total;
    },
    updateKeyword: (state, action) => {
      const { search_keyword } = action.payload;
      state.search_keyword = search_keyword;
    },
    updateReferer: (state, action) => {
      const { referer_id } = action.payload;
      state.referer_id = referer_id;
    },
    updateFilter: (state, action) => {
      const { filter } = action.payload;
      state.filter = filter;
    },
    updateAddItem: (state, action) => {
      const { referer_id } = action.payload;
      state.addItemRedirect = referer_id;
    },
    logoutUser: () => {
      return initialListStateSearch;
    }
  }
});

export const searchActions = parliamentListSlice.actions;
