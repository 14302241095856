import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/favorites`;
export const API_URL_STATUS = `${process.env.REACT_APP_API_URL}/favorites_statuses`;
export const API_URL_TYPES = `${process.env.REACT_APP_API_URL}/favorites_process_types`;
export const API_URL_USERS = `${process.env.REACT_APP_API_URL}/company_all_users`;
export const API_URL_FAVUSERS = `${process.env.REACT_APP_API_URL}/favorite_users`;

export function getParliamentList(
  queryParams = {},
  search_keyword = "",
  filter = {}
) {
  const {
    pageNumber = 1,
    pageSize = 5,
    sortField = "updated_at",
    sortOrder = "desc"
  } = queryParams;
  const { status, type, user } = filter;

  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      search_keyword !== "" ? `&filter[favorite_name]=${search_keyword}` : ""
    }${
      user !== undefined
        ? `&favorite_users=${user.map(res => res.id).join(",")}`
        : ""
    }${
      status !== undefined ? `&filter[favorite_process_status]=${status}` : ""
    }${
      type !== undefined ? `&filter[favorite_process_type]=${type}` : ""
    }&order=${sortField}&sort=${sortOrder}&favorite_type=team_overview`,
    data: {}
  };
  return axios(config);
}

export function getParliamentListId(id) {
  let config = {
    method: "get",
    url: `${API_URL}?filter[id]=${id}&favorite_type=team_overview`,
    data: {}
  };
  return axios(config);
}

export function getUsersInfo(id) {
  let config = {
    method: "get",
    url: `${API_URL_FAVUSERS}/${id}`,
    data: {}
  };
  return axios(config);
}

export function assignUsersFav(userDetails) {
  return axios.post(API_URL_FAVUSERS, userDetails);
}

export function removeParliamentList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function getStatus() {
  const headers = {
    data: {}
  };
  return axios.get(API_URL_STATUS, headers);
}

export function getTypes() {
  const headers = {
    data: {}
  };
  return axios.get(API_URL_TYPES, headers);
}

export function getUsers(filter = "") {
  let config = {
    method: "get",
    url: `${API_URL_USERS}${
      filter !== "" ? `?filter_user_name=${filter}` : ""
    }`,
    data: {}
  };
  return axios(config);
}
