import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { Brand } from "../brand/Brand";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";
import { useIntl } from "react-intl";

export function Aside() {
  const uiService = useHtmlClassService();
  const intl = useIntl();

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
        objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
        false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
        objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Aside */}
      <div
        id='kt_aside'
        className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
        style={{ minHeight: "100vh" }}
      >
        <Brand />

        {/* begin::Aside Menu */}
        <div
          id='kt_aside_menu_wrapper'
          className='aside-menu-wrapper flex-column-fluid'
          style={{ flex: "1 0 auto", overflowY: "auto" }}
        >
          {layoutProps.disableAsideSelfDisplay && (
            <>
              {/* begin::Header Logo */}
              <div className='header-logo'>
                <Link to=''>
                  <img alt='logo' src={layoutProps.headerLogo} />
                </Link>
              </div>
              {/* end::Header Logo */}
            </>
          )}
          <AsideMenu disableScroll={layoutProps.disableScroll} />
        </div>
        {/* end::Aside Menu */}
        <div
          id='kt_footer'
          style={{ flex: "0 0 auto", position: "fixed", bottom: 0 }}
        >
          <div
            style={{ padding: "0px 0px 8px 50px" }}
            className='code_help_text'
          >
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#7ABCFF",
                padding: "10px",
                border: "none"
              }}
              onClick={() => {
                window.open("https://codi.productfruits.help");
              }}
            >
              <SVG
                style={{ fill: "#002333" }}
                src={toAbsoluteUrl(
                  " /media/general/svg/icons/General/Help.svg"
                )}
              />
              <span
                className='font-weight-normal text-help-text'
                style={{ margin: 0 }}
              >
                {intl.formatMessage({
                  id: "HELP.CENTER"
                })}
              </span>
            </button>
          </div>
          <div style={{ padding: "0 0 15px 24px" }} className='code_help_icon'>
            <span className='svg-icon menu-icon'>
              <SVG
                onClick={() => {
                  window.open("https://codi.productfruits.help");
                }}
                style={{ fill: "#3599ff", cursor: "pointer" }}
                src={toAbsoluteUrl(
                  " /media/general/svg/icons/General/Help.svg"
                )}
              />
            </span>
          </div>
        </div>
      </div>
      {/* end::Aside */}
    </>
  );
}
