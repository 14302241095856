import axios from "axios";
import moment from "moment";

export const API_URL = `${process.env.REACT_APP_API_URL}/search`;
export const API_URL_FILES = `${process.env.REACT_APP_API_URL}/files`;

export function getSearchList(
  queryParams = {},
  searchKeyword = "",
  search_only_in_title = 0,
  dateFilter = {},
  subject = [],
  organization = "",
  index = [],
  document_type = [],
  file_number = "",
  file_subnumber = "",
  sortFilter = "",
  sortFilterStatus = false,
  subTypes = [],
  notSubTypes = [],
  sub_topic = [],
  topic_sub_is_session = 0
) {
  const { date_field, date_from, date_to } = dateFilter;
  const data = {
    params: {
      search_word: searchKeyword,
      search_only_in_title: search_only_in_title,
      topic_sub_is_session: 1,
      highlight_amount: 5,
      index: index,
      date_field: date_field,
      date_from: date_from
        ? moment(date_from, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      date_to: date_to
        ? moment(date_to, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      subject: subject,
      "frontend.publisher.organisation": organization,
      "frontend.type_primary": document_type,
      "document.generic.dossiernummers.dossiernummer": file_number,
      "document.generic.dossiernummers.ondernummer": file_subnumber,
      "frontend.type_secondary": subTypes,
      "not_frontend.type_secondary": notSubTypes,
      topic_sub_id: 0
    }
  };
  const { pageNumber, pageSize } = queryParams;
  const headerParams = {
    from: pageNumber * pageSize - pageSize,
    size: pageSize
  };

  if (sortFilter !== "" && sortFilterStatus) {
    const sort_order = sortFilter.split("__");
    Object.assign(headerParams, {
      sort_by: sort_order[0],
      order_by: sort_order[1]
    });
  }

  return axios.post(API_URL, data, {
    params: headerParams
  });
}

export function getBuckets(data = {}) {
  const headerParams = {
    from: 0,
    size: 20
  };
  return axios.post(API_URL, data, {
    params: headerParams
  });
}

export function getSearchListParams(queryParams = {}, params = {}) {
  const data = {
    params: params
  };

  const { pageNumber, pageSize } = queryParams;

  return axios.post(API_URL, data, {
    params: {
      from: pageNumber * pageSize - pageSize,
      size: pageSize
    }
  });
}

export function addFilesSearchList(favData, extension, file_name, author) {
  const data = {
    extension: extension,
    remote_file_download_url: favData,
    publication_level: "public",
    file_name: file_name,
    author: author
  };
  return axios.post(API_URL_FILES, data);
}
