import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/menu_items`;
export const API_URL_LOCALE = `${process.env.REACT_APP_API_URL}/menuItem/updateLocale`;

export function getMenuItemsList(queryParams = {}, referer_id = 0) {
  const {
    pageNumber = 1,
    pageSize = 10,
    sortField = "menu_item_position",
    sortOrder = "asc"
  } = queryParams;

  let config = {
    method: "get",
    url: `${API_URL}?from=${pageNumber * pageSize - pageSize}&size=${pageSize}${
      referer_id ? `&filter[menu_item_menu_id]=${referer_id}` : ""
    }&order=${sortField}&sort=${sortOrder}`,
    data: {}
  };
  return axios(config);
}

export function addMenuItemList(menuItemData) {
  return axios.post(API_URL, menuItemData);
}

export function updateMenuItemList(menuItemData, id) {
  return axios.put(`${API_URL}/${id}`, menuItemData);
}

export function removeMenuItemList(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function updateMenusItemName(menuItemsData) {
  return axios.post(API_URL_LOCALE, menuItemsData);
}

export function getMenuItemIcons() {
  const headers = {
    data: {}
  };
  return axios.get(`${API_URL}/icons`, headers);
}
