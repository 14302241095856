import axios from "axios";
export const API_URL = `${process.env.REACT_APP_API_URL}/search_history`;

export function getSearchHistory(queryParams = {}) {
  const { pageNumber, pageSize } = queryParams;
  return axios.get(API_URL, {
    params: {
      from: pageNumber * pageSize - pageSize,
      size: pageSize,
      sort_by: "search_date",
      sort_order: "desc"
    },
    data: {}
  });
}

export function removeSearchHistory(id = 0) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}
