import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { filterStatusActive } from "../../../../../general/template/helpers/FilterType";

export const filter_params = {
  filterStatus: false,
  dateFilter: {
    date_field: "frontend.published",
    date_from: "",
    date_to: ""
  },
  fileNumberFilter: {
    file_number: "",
    file_subnumber: ""
  },
  subjectFilter: {
    all: false,
    subjectItems: []
  },
  organizationFilter: {
    all: false,
    subjectItems: []
  },
  indexFilter: {
    all: false,
    subjectItems: [
      "rijksoverheid",
      "officiele_bekendmakingen_v3",
      "schade_door_mijnbouw"
    ]
  },
  documentTypeFilter: {
    all: false,
    parliament_docs: {
      all: false,
      subjectItems: [],
      subtypeSubjectItems: [],
      notSubtypeSubjectItems: []
    },
    official_docs: {
      all: false,
      subjectItems: []
    },
    policy_docs: {
      all: false,
      subtypeSubjectItems: []
    },
    other_docs: {
      all: false,
      subjectItems: []
    }
  }
};

const initialListState = {
  listLoading: false,
  totalCount: 0,
  countLoader: {
    default: false,
    kamervragen: false
  },
  entities: [],
  search_keyword: "",
  topic_sub_is_session: 0,
  sub_topic: 0,
  search_only_in_title: 0,
  filter: filter_params,
  sortFilter: "",
  sortFilterStatus: false,
  took: 0,
  sidebarFilter: [],
  kamervragenFilter: [],
  filterLoader: false,
  publication: {
    totalCount: 0,
    entities: [],
    search_keyword: "",
    listLoading: false
  },
  sub_topics: [],
  toggleCallKamervragenList: false,
  updateSidebarFilter: false
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const searchListSlice = createSlice({
  name: "searchList",
  initialState: initialListState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      }
    },
    catchError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      }
    },
    startPubCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.publication.listLoading = true;
      }
    },
    catchPubError: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.publication.listLoading = false;
      }
    },
    countLoader: (state, action) => {
      if (action.payload.default !== undefined) {
        state.countLoader.default = action.payload.default;
      }
      if (action.payload.kamervragen !== undefined) {
        state.countLoader.kamervragen = action.payload.kamervragen;
      }
    },
    listFetched: (state, action) => {
      const { value, hits, took, filters } = action.payload;
      state.listLoading = false;
      state.entities = hits;
      state.totalCount = value;
      state.took = took;
      state.sidebarFilter = filters;
    },
    loadFilter: (state, action) => {
      const { loader } = action.payload;
      state.filterLoader = loader;
    },
    kamervragenFilter: (state, action) => {
      const { filters } = action.payload;
      state.kamervragenFilter = filters;
      state.filterLoader = false;
    },
    updateKamervragenList: state => {
      state.toggleCallKamervragenList = !state.toggleCallKamervragenList;
    },
    publicationListFetched: (state, action) => {
      const { value, hits } = action.payload;
      state.publication.listLoading = false;
      state.publication.entities = hits;
      state.publication.totalCount = value;
    },
    updateKeyword: (state, action) => {
      const { search_keyword } = action.payload;
      state.search_keyword = search_keyword;
    },
    updateIsTopicSubSession: (state, action) => {
      const { topic_sub_is_session } = action.payload;
      state.topic_sub_is_session = topic_sub_is_session;
    },
    updateSubTopic: (state, action) => {
      const { sub_topic } = action.payload;
      state.sub_topic = sub_topic;
    },
    updatePublicationKeyword: (state, action) => {
      const { search_keyword } = action.payload;
      state.publication.search_keyword = search_keyword;
    },
    resetPublication: (state, action) => {
      const { search_keyword, hits, value } = action.payload;
      state.publication.search_keyword = search_keyword;
      state.publication.entities = hits;
      state.publication.totalCount = value;
      state.publication.listLoading = false;
    },
    updateSearchOnlyTitle: (state, action) => {
      const { search_only_in_title } = action.payload;
      state.search_only_in_title = search_only_in_title;
    },
    updateFilter: (state, action) => {
      const {
        filter_details: {
          dateFilter,
          filterStatus,
          fileNumberFilter,
          subjectFilter,
          organizationFilter,
          documentTypeFilter,
          indexFilter
        }
      } = action.payload;
      state.filter.dateFilter = dateFilter;
      state.filter.filterStatus = filterStatus;
      state.filter.fileNumberFilter = fileNumberFilter;
      state.filter.subjectFilter = subjectFilter;
      state.filter.organizationFilter = organizationFilter;
      state.filter.documentTypeFilter = documentTypeFilter;
      state.filter.indexFilter = indexFilter;
    },
    updateFilterStatus: (state, action) => {
      const { value } = action.payload;
      state.filter.filterStatus = value;
    },
    updateparams: (state, action) => {
      const {
        search_keyword,
        search_only_in_title,
        date_field,
        date_from,
        date_to,
        subject,
        organisation_primary,
        document_type,
        file_number,
        file_subnumber,
        all_types,
        sub_topic
      } = action.payload;
      state.search_keyword = search_keyword;
      state.sub_topic = sub_topic;
      state.search_only_in_title = search_only_in_title;

      state.filter.dateFilter.date_field = date_field;
      state.filter.dateFilter.date_from = date_from !== null ? date_from : "";
      state.filter.dateFilter.date_to = date_to !== null ? date_to : "";

      state.filter.fileNumberFilter.file_number =
        file_number !== null ? file_number : "";
      state.filter.fileNumberFilter.file_subnumber =
        file_subnumber !== null ? file_subnumber : "";

      state.filter.subjectFilter.subjectItems = subject;
      state.filter.subjectFilter.all = filterStatusActive(
        subject.length,
        "subject_filter_length"
      );

      state.filter.organizationFilter.subjectItems = organisation_primary;
      state.filter.organizationFilter.all = filterStatusActive(
        organisation_primary.length,
        "oragnization_filter_length"
      );

      state.filter.documentTypeFilter.parliament_docs.subjectItems = _.intersection(
        document_type,
        all_types.parliament_doctypes
      );
      const pa_status = filterStatusActive(
        _.intersection(document_type, all_types.parliament_doctypes).length,
        "parliament_filter_length"
      );
      state.filter.documentTypeFilter.parliament_docs.all = pa_status;

      state.filter.documentTypeFilter.official_docs.subjectItems = _.intersection(
        document_type,
        all_types.official_doctypes
      );
      const of_status = filterStatusActive(
        _.intersection(document_type, all_types.official_doctypes).length,
        "official_filter_length"
      );
      state.filter.documentTypeFilter.official_docs.all = of_status;

      state.filter.documentTypeFilter.other_docs.subjectItems = _.intersection(
        document_type,
        all_types.other_doctypes
      );
      const ot_status = filterStatusActive(
        _.intersection(document_type, all_types.other_doctypes).length,
        "other_filter_length"
      );
      state.filter.documentTypeFilter.other_docs.all = ot_status;

      state.filter.documentTypeFilter.all =
        pa_status === true && of_status === true && ot_status === true
          ? true
          : false;

      if (
        (date_from !== null && date_from !== "") ||
        (date_to !== null && date_to !== "") ||
        subject.length > 0 ||
        document_type.length > 0 ||
        (file_number !== null && file_number !== "") ||
        (file_subnumber !== null && file_subnumber !== "")
      ) {
        state.filter.filterStatus = true;
      } else {
        state.filter.filterStatus = false;
      }
    },
    resetFilter: (state, action) => {
      const { type } = action.payload;
      if (type === "filter") {
        state[type] = filter_params;
      }
      if (type === "documentTypeFilter") {
        const {
          value: {
            data,
            subtype,
            or_data,
            or_sub_data,
            not_sub_data,
            filterItem
          }
        } = action.payload;
        state.filter[type][subtype].all = false;
        if (subtype === "parliament_docs") {
          state.filter[type][subtype].subjectItems =
            filterItem === "subjectItems"
              ? or_data.filter(data_res => data_res !== data)
              : or_data;
          state.filter[type][subtype].subtypeSubjectItems =
            filterItem === "subtypeSubjectItems"
              ? or_sub_data.filter(data_res => data_res !== data)
              : or_sub_data;
          state.filter[type][subtype].notSubtypeSubjectItems =
            filterItem === "notSubtypeSubjectItems"
              ? not_sub_data.filter(data_res => data_res !== data)
              : not_sub_data;
        } else if (subtype === "policy_docs") {
          state.filter[type][subtype].subtypeSubjectItems = or_data.filter(
            data_res => data_res !== data
          );
        } else {
          state.filter[type][subtype].subjectItems = or_data.filter(
            data_res => data_res !== data
          );
        }

        state.filter[type].all = false;
      }
      if (type === "dateFilter") {
        const {
          value: { data }
        } = action.payload;
        state.filter[type] = data;
      }
      if (type === "fileNumberFilter") {
        const {
          value: { data, subtype }
        } = action.payload;
        state.filter[type][subtype] = data;
      }
      if (type === "subjectFilter") {
        const {
          value: { data, or_data }
        } = action.payload;
        state.filter[type].subjectItems = or_data.filter(
          data_res => data_res !== data
        );
        state.filter[type].all = false;
      }
      if (type === "organizationFilter") {
        const {
          value: { data, or_data }
        } = action.payload;
        state.filter[type].subjectItems = or_data.filter(
          data_res => data_res !== data
        );
        state.filter[type].all = false;
      }
      if (type === "indexFilter") {
        state.filter[type].subjectItems =
          filter_params.indexFilter.subjectItems;
        state.filter[type].all = false;
      }
    },
    sortFilter: (state, action) => {
      const { sortData, sortStatus } = action.payload;
      if (sortData !== undefined) {
        state.sortFilter = sortData;
      }
      if (sortStatus !== undefined) {
        state.sortFilterStatus = sortStatus;
      }
    },
    updateSubTopics: (state, action) => {
      const { subTopics } = action.payload;
      state.sub_topics = subTopics;
    },
    updateSidebarFilter: state => {
      state.updateSidebarFilter = !state.updateSidebarFilter;
    },
    logoutUser: () => {
      return initialListState;
    }
  }
});
