import axios from "axios";

export const API_URL = `${process.env.REACT_APP_API_URL}/topic_subs`;
export const API_URL_KEYWORDS = `${process.env.REACT_APP_API_URL}/topic_sub_key_words`;
export const API_URL_USERS = `${process.env.REACT_APP_API_URL}/topic_sub_users`;
export const API_URL_SUB_TOPIC_KEYWORD_TYPES = `${process.env.REACT_APP_API_URL}/search`;
export const API_URL_KEY_WORDS_EXPLANATION = `${process.env.REACT_APP_API_URL}/key_words_explanation`;
export const API_URL_EMAIL_NOTIFICATION = `${process.env.REACT_APP_API_URL}/topic_sub_user_mail_notification`;
export const API_URL_RESET = `${process.env.REACT_APP_API_URL}/topic_subs_session_reset`;
export const API_URL_SUMMARY = `${process.env.REACT_APP_API_URL}/document_summary`;

export function resetSubTopicSession() {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.put(API_URL_RESET, {
    headers,
    data: {}
  });
}

export function getKeyWordsExplanation(sub_topic_id) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.get(
    `${API_URL_KEY_WORDS_EXPLANATION}?topic_sub_id=${sub_topic_id}`,
    {
      headers,
      data: {}
    }
  );
}

export function fetchSummary(
  document_identifiers,
  session_topic_sub_id,
  topic_sub_id,
  type = "document"
) {
  const data = {
    params: {
      document_identifiers
    }
  };
  if (session_topic_sub_id) {
    data["params"]["session_topic_sub_id"] = parseInt(session_topic_sub_id);
  }

  if (type === "analyse" || type === "askquestion") {
    data["params"]["topic_sub_id"] = parseInt(topic_sub_id);
  } else if (topic_sub_id) {
    data["params"]["topic_sub_id"] = parseInt(topic_sub_id);
  }

  return axios.post(`${API_URL_SUMMARY}`, data);
}

export function getEmailNotification(sub_topic_id) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.get(`${API_URL}?topic_sub_id=${sub_topic_id}`, {
    headers,
    data: {}
  });
}

export function postEmailNotification(
  topic_sub_id,
  topic_sub_user_id,
  mail_notification,
  mail_notification_frequency,
  topic_sub_mail_notification_type
) {
  const type =
    topic_sub_mail_notification_type === "default"
      ? "mail_notification"
      : "mail_notification_kamervragen";
  const frequency =
    topic_sub_mail_notification_type === "default"
      ? "mail_notification_frequency"
      : "mail_notification_frequency_kamervragen";

  const data = {
    topic_sub_id,
    topic_sub_user_id,
    [type]: mail_notification,
    [frequency]: mail_notification_frequency
  };
  return axios.post(API_URL_EMAIL_NOTIFICATION, data);
}

export function getSubTopic(id, sub_topic_id) {
  const headers = {
    "Content-Type": "application/json"
  };
  let getParam = "?topic_id=" + id;
  getParam += "&topic_sub_id=" + sub_topic_id;
  return axios.get(API_URL + getParam, { headers, data: {} });
}

export function getSubTopics(
  id,
  user_id = 0,
  type = "default",
  exclude_is_session = 0,
  topic_sub_type = true,
  include_public_topic_subs = false
) {
  const headers = {
    "Content-Type": "application/json"
  };

  let getParam = "?topic_id=" + id;
  if (user_id !== 0) {
    getParam += "&filter_on_user_id=" + user_id;
  }
  if (exclude_is_session !== 0) {
    getParam += "&exclude_is_session=" + exclude_is_session;
  }
  if (include_public_topic_subs) {
    getParam += "&include_public_topic_subs=1";
  }
  if (topic_sub_type) getParam += "&topic_sub_type=" + type;
  return axios.get(API_URL + getParam, { headers, data: {} });
}

export function getSubTopicKeywords(id, not_key_word_type) {
  const headers = {
    "Content-Type": "application/json"
  };
  let url = `${API_URL_KEYWORDS}?topic_sub_id=${id}`;
  if (not_key_word_type === "not_key_word_type") {
    url = `${url}&not_key_word_type=search_word`;
  }
  return axios.get(url, {
    headers,
    data: {}
  });
}

export function saveSubTopic(
  topic_id,
  topic_sub_title,
  topic_sub_colour,
  topic_type,
  topic_sub_publication_level,
  frontend_key_words_array,
  topic_sub_is_session = 0
) {
  const data = {
    topic_id: topic_id,
    topic_sub_title: topic_sub_title,
    topic_sub_colour: topic_sub_colour,
    topic_sub_type: topic_type,
    topic_sub_publication_level: topic_sub_publication_level,
    frontend_key_words_array: frontend_key_words_array,
    topic_sub_is_session: topic_sub_is_session
  };
  return axios.post(API_URL, data);
}

export function updateSubTopic(
  sub_topic_id,
  topic_sub_title,
  topic_sub_colour,
  frontend_key_words_array,
  topic_type,
  topic_sub_is_session = 0,
  topic_sub_publication_level = ""
) {
  const data = {
    topic_sub_title: topic_sub_title,
    topic_sub_colour: topic_sub_colour,
    frontend_key_words_array: frontend_key_words_array,
    topic_sub_type: topic_type,
    topic_sub_is_session: topic_sub_is_session,
    topic_sub_publication_level: topic_sub_publication_level
  };
  return axios.put(`${API_URL}/${sub_topic_id}`, data);
}

export function saveSubTopicKeywords(
  topic_sub_id,
  key_words,
  not_key_word_type = ""
) {
  const data = {
    topic_sub_id: topic_sub_id,
    topic_sub_key_words: key_words,
    not_key_word_type: not_key_word_type
  };
  return axios.post(API_URL_KEYWORDS, data);
}

export function deleteSubTopic(id) {
  const headers = {
    "Content-Type": "application/json"
  };
  return axios.delete(`${API_URL}/${id}`, { headers, data: {} });
}

export function updateSubTopicUsers(topic_sub_id, users) {
  const topic_users = [];
  users.map(value => {
    return topic_users.push({ topic_sub_user_id: value.id });
  });
  const data = {
    topic_sub_id: topic_sub_id,
    topic_sub_users: topic_users
  };
  return axios.post(API_URL_USERS, data);
}

export function getSubTopicKeywordTypes(config) {
  let updatedConfig = { ...config };
  delete updatedConfig["size"];
  delete updatedConfig["from"];
  return axios.post(
    `${API_URL_SUB_TOPIC_KEYWORD_TYPES}?size=${
      config.size ? config.size : 20
    }&from=${config.from ? config.from : 0}`,
    updatedConfig
  );
}

export function updateSubTopicUsersMailNotification(topic_sub_id, users) {
  const topic_users = [];
  users.map(value => {
    return topic_users.push({
      topic_sub_user_id: value.id,
      mail_notification: value.mail_notification
    });
  });
  const data = {
    topic_sub_id: topic_sub_id,
    topic_sub_users: topic_users
  };
  return axios.post(API_URL_USERS, data);
}
