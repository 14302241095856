import { getSubTopicKeywordTypes, getSubTopics } from "./subTopicCrud";
import { callTypes, topicsSlice } from "./topicsSlice";
const { actions: topicActions } = topicsSlice;
export const fetchSubTopics = (params = {}) => async () => {
  const { id, user_id } = params;
  const response = await getSubTopics(id, user_id, "", 1, false);
  const { data } = response.data;
  return data;
};
export const fetchSubTopicKeywordTypes = params => async dispatch => {
  const response = await getSubTopicKeywordTypes(params);
  const { data } = response.data;
  if (data) {
    dispatch(topicActions.setSubTopicKeywordTypes(data));
  }
};
export const fetchSubTopicKeywordValueForType = params => async dispatch => {
  dispatch(
    topicActions.startKeywordValueCall({
      callType: callTypes.list,
      id: params.id
    })
  );
  const updatedParams = { ...params };
  delete updatedParams["id"];
  updatedParams["from"] = params.from;
  updatedParams["size"] = params.size;
  let bucketName = updatedParams?.["params"]?.buckets?.[0];

  const response = await getSubTopicKeywordTypes(updatedParams);
  const { data, status } = response.data;

  if (data) {
    if (status) {
      if (
        data?.["buckets"]?.[bucketName]?.[0].length <= 20 &&
        params.size === 25
      ) {
        dispatch(
          topicActions.setSubTopicKeywordValuesForType({
            data: data,
            id: params.id,
            isError: false,
            isFinalDataLoaded: true
          })
        );
      } else if (
        data?.["buckets"]?.[bucketName]?.[0].length > 20 &&
        params.size === 25
      ) {
        dispatch(
          topicActions.setSubTopicKeywordValuesForType({
            data: data,
            id: params.id,
            isError: false,
            isFinalDataLoaded: false
          })
        );
      } else
        dispatch(
          topicActions.setSubTopicKeywordValuesForType({
            data: data,
            id: params.id,
            isError: false,
            isFinalDataLoaded: true
          })
        );
    } else
      dispatch(
        topicActions.setSubTopicKeywordValuesForType({
          data: data,
          id: params.id,
          isError: true
        })
      );
  }
  dispatch(
    topicActions.endKeywordValueCall({
      callType: callTypes.list,
      id: params.id
    })
  );
  return data?.buckets ? data?.buckets : {};
};
