import * as requestFromServer from "./adminPagesCrud";
import { callTypesPages, adminPagesSlice } from "./adminPagesSlice";

const { actions: adminPagesActions } = adminPagesSlice;

export const fetchAllTypes = () => async () => {
  const response = await requestFromServer.getTypes();
  const {
    data: { pageTypes }
  } = response.data;
  return pageTypes;
};

export const updatePagesName = (
  details,
  queryParams,
  search_keyword = {},
  search_status = "",
  search_page_type = ""
) => async dispatch => {
  dispatch(adminPagesActions.startCall({ callType: callTypesPages.list }));
  try {
    const response = await requestFromServer.updatePagesName(details);
    const { status, message, message_code, message_level } = response.data;
    if (status) {
      const response = await requestFromServer.getPagesList(
        queryParams,
        search_keyword,
        search_status,
        search_page_type
      );
      const {
        data: { pages, total }
      } = response.data;

      dispatch(
        adminPagesActions.storeSearch({
          hits_total: total,
          sources: pages
        })
      );
    }
    return {
      status: status,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
  }
};

export const fetchPowerBI = () => async () => {
  const response = await requestFromServer.getPowerBI();
  const {
    data: { azurePowerBi }
  } = response.data;
  return azurePowerBi;
};

export const fetchAllPages = () => async () => {
  const queryParams = {
    pageNumber: 0,
    pageSize: 0,
    sortField: "page_name",
    sortOrder: "asc"
  };
  const response = await requestFromServer.getPagesList(queryParams);
  const {
    data: { pages }
  } = response.data;
  return pages;
};

export const fetchAdminPages = (
  queryParams,
  search_keyword = {},
  search_status = "",
  search_page_type = ""
) => async dispatch => {
  dispatch(adminPagesActions.startCall({ callType: callTypesPages.list }));
  try {
    const response = await requestFromServer.getPagesList(
      queryParams,
      search_keyword,
      search_status,
      search_page_type
    );
    const {
      data: { pages, total }
    } = response.data;
    setTimeout(() => {
      dispatch(
        adminPagesActions.storeSearch({
          hits_total: total,
          sources: pages
        })
      );
    }, 500);
  } catch (e) {
    dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
  }
};

export const addAdminPages = data => async dispatch => {
  dispatch(adminPagesActions.startCall({ callType: callTypesPages.list }));
  try {
    const response = await requestFromServer.addPagesList(data);
    const { status, message, message_code, message_level } = response.data;

    if (status) {
      const {
        data: { id }
      } = response.data;

      const responsePage = await requestFromServer.getPagesList();
      const {
        data: { pages, total }
      } = responsePage.data;

      dispatch(
        adminPagesActions.storeSearch({
          hits_total: total,
          sources: pages
        })
      );
      return {
        id: id,
        status: status,
        msg: message,
        message_code: message_code,
        message_level: message_level
      };
    }
    return {
      status: status,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
  }
};

export const updateAdminPages = (
  queryParams,
  search_keyword,
  data,
  pageId,
  status,
  pageType,
  saveExitState
) => async dispatch => {
  dispatch(adminPagesActions.startCall({ callType: callTypesPages.list }));
  try {
    const response = await requestFromServer.updatePagesList(pageId, data);
    const {
      status: resStatus,
      message,
      message_code,
      message_level
    } = response.data;

    if (resStatus && !saveExitState) {
      const response = await requestFromServer.getPagesList(
        queryParams,
        search_keyword,
        status,
        pageType
      );
      const {
        data: { pages, total }
      } = response.data;

      dispatch(
        adminPagesActions.storeSearch({
          hits_total: total,
          sources: pages
        })
      );
    }
    return {
      status: resStatus,
      msg: message,
      message_code: message_code,
      message_level: message_level
    };
  } catch (e) {
    return { status: false };
  } finally {
    dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
  }
};

export const removeAdminPages = (
  queryParams,
  id,
  search_keyword,
  searchStatus,
  searchPageType
) => async dispatch => {
  dispatch(adminPagesActions.startCall({ callType: callTypesPages.list }));
  try {
    const response = await requestFromServer.removePagesList(id);
    const { status } = response.data;
    if (status) {
      setTimeout(() => {
        requestFromServer
          .getPagesList(
            queryParams,
            search_keyword,
            searchStatus,
            searchPageType
          )
          .then(response_1 => {
            const {
              data: { pages, total }
            } = response_1.data;
            setTimeout(() => {
              dispatch(
                adminPagesActions.storeSearch({
                  hits_total: total,
                  sources: pages
                })
              );
            }, 500);
          })
          .catch(() => {
            dispatch(
              adminPagesActions.catchError({
                callType: callTypesPages.list
              })
            );
          });
      }, 500);
    } else {
      dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
    }
  } catch (error_1) {
    dispatch(adminPagesActions.catchError({ callType: callTypesPages.list }));
  }
};
