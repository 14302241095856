import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { resetPassword } from "../_redux/authCrud";
import { Link, useHistory } from "react-router-dom";
import { useIsMounted } from "../../../../template/helpers/useIsMounted";
import { useSnackbarContext } from "../../../../template/layout/components/snackbars/SnackbarProvider";

const {
  actions
} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

const initialValues = {
  password: "",
  password2: ""
};

function ResetPassword(props) {
  const {
    intl,
    match: {
      params: { token }
    }
  } = props;
  const history = useHistory();
  const snackbarContext = useSnackbarContext();

  const snackbarUIProps = useMemo(() => {
    return {
      queryParams: snackbarContext.params,
      setQueryParams: snackbarContext.setParams
    };
  }, [snackbarContext]);

  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        6,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MIN_LENGTH"
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "AUTH.VALIDATION.MAX_LENGTH"
        })
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password2: Yup.string()
      .required(
        intl.formatMessage({
          id: "PASS.REQ"
        })
      )
      .oneOf(
        [Yup.ref("password"), null],
        intl.formatMessage({
          id: "PASS.UNMATCH"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        resetPassword(values.password, token)
          .then(res => {
            const { status, message, message_code, message_level } = res.data;
            disableLoading();
            if (status) {
              snackbarUIProps.setQueryParams(prev => ({
                ...prev,
                open: true,
                variant:
                  message_level !== undefined
                    ? message_level.toLowerCase()
                    : "success",
                message:
                  message_code !== undefined ? (
                    <div>
                      <p className='mb-1'>{`${intl.formatMessage({
                        id: "CODE"
                      })}: ${message_code}`}</p>
                      <p className='mb-1'>{message}</p>
                    </div>
                  ) : (
                    message
                  ),
                vertical: "top",
                horizontal: "right"
              }));
              setTimeout(() => {
                history.push("/auth/login");
              }, 1000);
            }
          })
          .finally(() => {
            if (isMounted()) {
              disableLoading();
              setSubmitting(false);
            }
          });
      }, 1000);
    }
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      <div className='text-center mb-10'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.RESET.PASS.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          <FormattedMessage id='AUTH.RESET.PASS.DESC' />
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        <div className='form-group fv-plugins-icon-container'>
          <label className={"font-weight-bold"}>
            {intl.formatMessage({ id: "PLACEHOLDER.PASS" })}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: "PLACEHOLDER.PASS"
            })}
            type='password'
            autoComplete='off'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name='password'
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <label className={"font-weight-bold"}>
            {intl.formatMessage({ id: "VERIFY.PASS" })}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: "VERIFY.PASS"
            })}
            type='password'
            autoComplete='off'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password2"
            )}`}
            name='password2'
            {...formik.getFieldProps("password2")}
          />
          {formik.touched.password2 && formik.errors.password2 ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password2}</div>
            </div>
          ) : null}
        </div>
        <div
          className='form-group d-flex flex-wrap justify-content-center align-items-center'
          style={{ gap: 10 }}
        >
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
          >
            <span>
              <FormattedMessage id='ENTER' />
            </span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
          <Link
            className={`btn btn-secondary font-weight-bold px-9 py-3 my-3`}
            to='/auth/login'
          >
            <FormattedMessage id='BACK' />
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, actions)(ResetPassword));
