import React from "react";
import { toAbsoluteUrl } from "../../../template/helpers";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export function ErrorPage() {
  const {
    profile: { menu }
  } = useSelector((state) => state.auth, shallowEqual);

  const history = useHistory();

  const logoutHandler = () => {
    history.push("/logout");
  };

  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30'
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/general/error/bg1.jpg")})`
        }}
      >
        <h1
          className='font-size-sm-100 font-weight-boldest text-dark-75 mt-15'
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className='font-size-h3 font-weight-light'>
          {!menu.hasOwnProperty("menu_items") ? (
            <FormattedMessage id='ERROR.MENU.EMPTY' />
          ) : (
            <FormattedMessage id='ERROR.PAGE' />
          )}
        </p>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => logoutHandler()}
          >
            <FormattedMessage id='ERROR.GOBACK' />
          </button>
        </div>
      </div>
    </div>
  );
}
