import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { reducer, saga } from "../app/modules/Auth/_redux/authRedux";
import { searchListSlice } from "../app/modules/SearchList";
import { searchHistorySlice } from "../app/modules/SearchHistory";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { dynamicSlice } from "../../general/app/modules/DynamicSections";
import { favoriteListSlice } from "../app/modules/FavoriteList";
import { parliamentListSlice } from "../app/modules/ParliamentOverview";
import {
  adminCompaniesSlice,
  adminUsersSlice,
  adminPagesSlice,
  adminMenusSlice,
  adminMenuItemsSlice
} from "../../general/app/modules/AdminList";
import { newsListSlice } from "../app/modules/NewsList";
import { topicsSlice } from "../app/modules/Topics";
import { analyseListSlice } from "../app/modules/Kamervragen";
import { askQuestionListSlice } from "../app/modules/AskQuestion";

export const rootReducer = combineReducers({
  auth: reducer,
  dynamic: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    dynamicSlice.reducer
  ),
  newsList: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    newsListSlice.reducer
  ),
  analyseList: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    analyseListSlice.reducer
  ),
  askQuestionList: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    askQuestionListSlice.reducer
  ),
  searchList: persistReducer(
    {
      key: "root1",
      storage,
      stateReconciler: autoMergeLevel2
    },
    searchListSlice.reducer
  ),
  searchHistory: persistReducer(
    {
      key: "root2",
      storage,
      stateReconciler: autoMergeLevel2
    },
    searchHistorySlice.reducer
  ),
  favoriteList: persistReducer(
    {
      key: "root3",
      storage,
      stateReconciler: autoMergeLevel2
    },
    favoriteListSlice.reducer
  ),
  adminCompanies: persistReducer(
    {
      key: "root4",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminCompaniesSlice.reducer
  ),
  adminUsers: persistReducer(
    {
      key: "root5",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminUsersSlice.reducer
  ),
  adminPages: persistReducer(
    {
      key: "root6",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminPagesSlice.reducer
  ),
  adminMenus: persistReducer(
    {
      key: "root7",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminMenusSlice.reducer
  ),
  adminMenuItems: persistReducer(
    {
      key: "root8",
      storage,
      stateReconciler: autoMergeLevel2
    },
    adminMenuItemsSlice.reducer
  ),
  parliamentOverview: persistReducer(
    {
      key: "root9",
      storage,
      stateReconciler: autoMergeLevel2
    },
    parliamentListSlice.reducer
  ),
  topics: persistReducer(
    {
      key: "root10",
      storage,
      stateReconciler: autoMergeLevel2
    },
    topicsSlice.reducer
  )
});

export function* rootSaga() {
  yield all([saga()]);
}
