export const staticPageTypes = {
  azure_power_bi: "azure_power_bi",
  content: "content",
  under_construction: "under_construction"
};

export const menuIcon = {
  icon_dashboard_ref: "Dashboard.svg",
  icon_search_ref: "Search.svg",
  icon_history_ref: "History.svg",
  icon_profile_ref: "Add-user.svg",
  icon_powerbi_ref: "Dashboard.svg",
  icon_search_favorites_ref: "Favorite.svg",
  icon_search_team_overview: "Parliament.svg",
  icon_documents: "Documents.svg",
  icon_company_ref: "Companies.svg",
  icon_user_ref: "Users.svg",
  icon_page_ref: "Pages.svg",
  icon_menu_ref: "Menu.svg"
};

export const menuPage = {
  company_item_page: "admin_company",
  user_item_page: "admin_user",
  pages_item_page: "admin_pages",
  menus_item_page: "admin_menus"
};

export const pageRef = {
  page_dashboard_ref: "page_dashboard_ekz_ref",
  page_search_ref: "page_search_ekz_ref",
  page_history_ref: "page_history_ekz_ref",
  dashboard: "dashboard",
  page_admin_ref: "page_admin_dashboard",
  page_favorites_ref: "page_search_favorites_ref",
  page_admin_company: "page_admin_company",
  page_admin_user: "page_admin_user",
  page_admin_page: "page_admin_pages",
  page_admin_menu: "page_admin_menus",
  page_team_ref: "page_search_team_overview",
  page_codi_ref: "page_heptagon_codi_dashboard",
  page_kamervragen_ref: "page_heptagon_kamervragen"
};

export const subTopicKeywordTypesRef = {
  keyword_type_ref: "search_word",
  document_type_ref: "listms__Type document__frontend.type_primary",
  organisation_type_ref: "listms__Organisatie__frontend.publisher.organisation",
  source_label_type_ref: "listms__Bron__frontend.source.label",
  dossiernummers_ref: "dossiernummers",
  date_from_to_ref: "date_from_to"
};
