import {
  subTypeDocTypeMapping,
  subTypeMapping
} from "../../../../template/partials/advancedSearch/Mapping";
import * as requestFromServer from "./searchListCrud";
import { callTypes, searchListSlice } from "./searchListSlice";
import _ from "lodash";

const { actions: searchActions } = searchListSlice;

export const fetchList = (
  queryParams,
  searchKeyword,
  search_only_in_title,
  filterList,
  sortFilter,
  sortFilterStatus,
  sub_topic,
  topic_sub_is_session
) => async dispatch => {
  const {
    dateFilter,
    subject,
    organization,
    index,
    allDocTypes,
    file_number,
    file_subnumber,
    subTypes,
    notSubTypes
  } = filterList;
  dispatch(searchActions.startCall({ callType: callTypes.list }));

  let subTypesArr = [];
  for (let index = 0; index < subTypes.length; index++) {
    if (subTypes[index] !== "") {
      const typeIndex = _.findKey(subTypeMapping, function(o) {
        return o === subTypes[index];
      });
      if (
        typeIndex !== undefined &&
        !allDocTypes.includes(subTypeDocTypeMapping[typeIndex])
      ) {
        allDocTypes.push(subTypeDocTypeMapping[typeIndex]);
      }
      subTypesArr = _.union(
        subTypesArr,
        subTypes[index].split(",").map(function(value) {
          return value.trim();
        })
      );
    }
    if (
      subTypes[index] === "" &&
      !allDocTypes.includes(
        subTypeDocTypeMapping["Kamervragen zonder antwoord"]
      )
    ) {
      allDocTypes.push(subTypeDocTypeMapping["Kamervragen zonder antwoord"]);
    }
  }

  let notSubTypesArr = [];
  for (let index = 0; index < notSubTypes.length; index++) {
    if (notSubTypes[index] !== "") {
      const typeIndex = _.findKey(subTypeMapping, function(o) {
        return o === notSubTypes[index];
      });
      if (
        typeIndex !== undefined &&
        !allDocTypes.includes(subTypeDocTypeMapping[typeIndex])
      ) {
        allDocTypes.push(subTypeDocTypeMapping[typeIndex]);
      }
      notSubTypesArr = _.union(
        notSubTypesArr,
        notSubTypes[index].split(",").map(function(value) {
          return value.trim();
        })
      );
    }
  }

  try {
    dispatch(
      searchActions.countLoader({
        default: true
      })
    );
    const response = await requestFromServer.getSearchList(
      queryParams,
      searchKeyword,
      search_only_in_title,
      dateFilter,
      subject,
      organization,
      index,
      allDocTypes,
      file_number,
      file_subnumber,
      sortFilter,
      sortFilterStatus,
      subTypesArr,
      notSubTypesArr,
      sub_topic,
      topic_sub_is_session
    );
    const {
      data: {
        hits: { hits },
        took
      },
      filters,
      total_hits: value
    } = response.data;
    dispatch(searchActions.listFetched({ value, hits, took, filters }));
  } catch (e) {
    dispatch(searchActions.catchError({ callType: callTypes.list }));
  } finally {
    dispatch(
      searchActions.countLoader({
        default: false
      })
    );
  }
};

export const fetchListParams = (queryParams, params) => async dispatch => {
  dispatch(searchActions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getSearchListParams(
      queryParams,
      params
    );
    const {
      data: {
        hits: { hits },
        took
      },
      total_hits: value
    } = response.data;
    dispatch(searchActions.listFetched({ value, hits, took }));
  } catch (e) {
    dispatch(searchActions.catchError({ callType: callTypes.list }));
  }
};

export const fetchListPublications = (
  queryParams,
  params
) => async dispatch => {
  dispatch(searchActions.startPubCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getSearchListParams(
      queryParams,
      params
    );
    const {
      data: {
        hits: {
          total: { value },
          hits
        }
      }
    } = response.data;
    dispatch(searchActions.publicationListFetched({ value, hits }));
  } catch (e) {
    dispatch(searchActions.catchPubError({ callType: callTypes.list }));
  }
};
