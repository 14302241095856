import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../_core/MetronicLayout";
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import { SubHeader } from "./subheader/SubHeader";
import { ScrollTop } from "./extras/ScrollTop";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);

  const {
    profile: { menu, pages }
  } = useSelector((state) => state.auth, shallowEqual);

  const history = useHistory();
  const {
    location: { pathname }
  } = history;

  let data = _.find(menu.menu_items, function(o) {
    return o.menu_item_reference === pathname.split("/")[1];
  });

  let powerBiData = undefined;
  if (data !== undefined) {
    powerBiData = _.find(pages, function(o) {
      return o.id === data.menu_item_page;
    });
  }

  return layoutProps.selfLayout !== "blank" ? (
    <>
      <HeaderMobile />
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-row flex-column-fluid page'>
          {layoutProps.asideDisplay && <Aside />}
          <div
            className='d-flex flex-column flex-row-fluid wrapper'
            id='kt_wrapper'
          >
            <Header />
            <div
              id='kt_content'
              className={`${
                powerBiData !== undefined &&
                powerBiData.page_type !== "azure_power_bi"
                  ? "content"
                  : "powerBiMode"
              } ${
                layoutProps.contentCssClasses
              } d-flex flex-column flex-column-fluid`}
            >
              {layoutProps.subheaderDisplay && <SubHeader />}
              {!layoutProps.contentExtended && (
                <div className='d-flex flex-column-fluid'>
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                </div>
              )}

              {layoutProps.contentExtended && { children }}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
      <LayoutInit />
    </>
  ) : (
    <div className='d-flex flex-column flex-root'>{children}</div>
  );
}
