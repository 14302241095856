import React, { useEffect, useMemo } from "react";
import { check_heartbeat } from "./hearbeatActions";
import { useSnackbarContext } from "../../../../template/layout/components/snackbars/SnackbarProvider";
import { shallowEqual, useSelector } from "react-redux";
import store from "../../../../redux/store";

const {
  actions
} = require(`../../../../../project-${process.env.REACT_APP_PROJECT}/app/modules/Auth/_redux/authRedux`);

export default function HeartbeatInit(props) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  const snackbarContext = useSnackbarContext();
  const snackbarUIProps = useMemo(() => {
    return {
      queryParams: snackbarContext.params,
      setQueryParams: snackbarContext.setParams
    };
  }, [snackbarContext]);

  function checkHeartbeat() {
    check_heartbeat().then(res => {
      if (res && res.data !== undefined) {
        const { status, message, message_level } = res.data;
        if (status && message_level) {
          snackbarUIProps.setQueryParams(prev => ({
            ...prev,
            open: true,
            variant: message_level.toLowerCase(),
            message: message,
            vertical: "top",
            horizontal: "right"
          }));
        }
        if (!status) {
          store.dispatch(actions.logout());
        }
      }
    });
  }

  useEffect(() => {
    let intervalID;
    if (isAuthorized) {
      checkHeartbeat();
      intervalID = setInterval(function() {
        checkHeartbeat();
      }, (process.env.REACT_APP_HEARTBEAT_INTERVAL !== undefined
        ? process.env.REACT_APP_HEARTBEAT_INTERVAL
        : 120) * 1000);
    }
    return () => clearInterval(intervalID);
    // eslint-disable-next-line
  }, [isAuthorized]);

  return <>{props.children}</>;
}